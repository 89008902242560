import { useAtomValue, useSetAtom } from "jotai";
import { useOrderCalculation } from "../../api/hooks";
import {
  CharterTypeReturnType,
  CustomPartDayCharterType,
} from "../../api/types";
import { getPartDaySlotDates, isSlotUnavailable, removeTimezoneFromISO } from "../../utils/date";
import SlotComponent from "./SlotComponent";
import {
  selectedDateAsStringAtom,
  boatIdAtom,
  passengersAtom,
  selectedDayUnavailabilitiesAtom,
  selectedCalculationAtom,
  selectedAddonsAtom,
  selectedSlotAtom,
  selectedCharterTypeAtom,
} from "./store";
import { formatISO } from "date-fns";
import { interval } from "date-fns/interval";
import SlotUnavailableMessage from "./SlotUnavailableMessage";

type Slot = {
  startTimeFormatted: string;
  endTimeFormatted: string;
  slotInterval: ReturnType<typeof interval>;
  slotUnavailable: boolean;
  id: number;
};

export default function PartDayCharterSlots({
  handleNextStep,
}: {
  handleNextStep: () => void;
}) {
  const selectedDayString = useAtomValue(selectedDateAsStringAtom);
  const selectedDayUnavailabilities = useAtomValue(
    selectedDayUnavailabilitiesAtom
  );

  const selectedCharterType = useAtomValue(selectedCharterTypeAtom);

  const slots = (selectedCharterType as CustomPartDayCharterType)!.Hours.sort(
    (hour1, hour2) => hour1.StartTime!.Hours - hour2.StartTime!.Hours
  ).map((hour: CharterTypeReturnType) => {
    const { startTimeFormatted, endTimeFormatted, slotInterval } =
      getPartDaySlotDates(hour, selectedDayString);

    const slotUnavailable = selectedDayUnavailabilities.some((unavailability) =>
      isSlotUnavailable(unavailability, slotInterval)
    );

    return {
      startTimeFormatted,
      endTimeFormatted,
      slotInterval,
      slotUnavailable,
      id: hour.Id,
    };
  });

  const allSlotsUnavailable = slots.every((slot) => slot.slotUnavailable);

  return (
    <div>
      <ul className="flex flex-col gap-4">
        {slots.map((slot) => (
          <li key={slot.id}>
            <PartDayCharterSlot slot={slot} handleNextStep={handleNextStep} />
          </li>
        ))}
      </ul>
      {allSlotsUnavailable && <SlotUnavailableMessage />}
    </div>
  );
}

const PartDayCharterSlot = ({
  handleNextStep,
  slot: {
    id,
    startTimeFormatted,
    endTimeFormatted,
    slotInterval,
    slotUnavailable,
  },
}: {
  slot: Slot;
  handleNextStep: () => void;
}) => {
  const boatId = useAtomValue(boatIdAtom);
  const passengers = useAtomValue(passengersAtom);
  const selectedAddons = useAtomValue(selectedAddonsAtom);
  const setSelectedCalculation = useSetAtom(selectedCalculationAtom);
  const setSelectedSlot = useSetAtom(selectedSlotAtom);

  const { data: calculation, loading } = useOrderCalculation(
    {
      Items: [
        {
          PricingId: id,
          Passengers: passengers,
          Date: removeTimezoneFromISO(formatISO(slotInterval.start)),
          BoatId: boatId!,
          AddonsIds: [],
          AdditionalItemsIds: selectedAddons,
        },
      ],
      Currency: "USD",
    },
    !boatId || slotUnavailable
  );

  return (
    <SlotComponent
      startTimeFormatted={startTimeFormatted}
      endTimeFormatted={endTimeFormatted}
      price={calculation?.TotalAmount}
      handleBook={() => {
        setSelectedCalculation(calculation);
        setSelectedSlot({
          charterTypeId: id,
          interval: slotInterval,
        });
        handleNextStep();
      }}
      disabled={slotUnavailable}
      loading={loading}
    />
  );
};
