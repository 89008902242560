import { useEffect, useState } from "react";
import {
  AdditionalItemReturnType,
  CalculationPayloadType,
  CalculationReturnType,
  ConfirmTransactionReturnType,
  OrderPayloadType,
  OrderReturnType,
} from "./types";
import { customFetch } from "./common";
import { API_PATH } from "./paths";

const useCommonQuery = <
  ReturnType extends Record<string, unknown>,
  Payload extends Record<string, unknown> = never
>(
  path: string | null,
  disabled = false,
  method = "GET",
  payload?: Payload
) => {
  const [data, setData] = useState<ReturnType | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!path || disabled) return;
    setLoading(true);
    customFetch<Payload>(path, method, payload)
      .then((res) => res.json())
      .then(setData)
      .catch(setError)
      .finally(() => setLoading(false));
  }, [path, JSON.stringify(payload)]);

  return { data, loading, error };
};

const useCommonMutation = <
  Payload extends Record<string, unknown>,
  ReturnType extends Record<string, unknown> | string
>(
  path: string,
  method = "POST"
) => {
  const mutate = async (body?: Payload): Promise<ReturnType | null> => {
    const res = await customFetch<Payload>(path, method, body);

    if (!res.ok) {
      throw new Error("Failed to fetch");
    }

    const resObj = await res.json();
    return resObj as ReturnType;
  };

  return { mutate };
};

// [POST]Orders/Calculation
export const useOrderCalculation = (
  payload: CalculationPayloadType,
  disabled: boolean
) =>
  useCommonQuery<CalculationReturnType, CalculationPayloadType>(
    API_PATH.OrderCalculationPOST(),
    disabled,
    "POST",
    payload
  );

// [POST]Orders
export const useOrderMutation = () =>
  useCommonMutation<OrderPayloadType, OrderReturnType>(API_PATH.OrderPOST());

// [POST]Checkout/confirm-transaction/{orderIntentId}
export const useConfirmTransactionMutation = (orderIntentId: string) =>
  useCommonMutation<never, ConfirmTransactionReturnType>(
    API_PATH.PaymentStatusGET(orderIntentId),
    "GET"
  );

export const useAdditionalItems = (
  boatId: number | null,
  charterTypeId?: number | null
) =>
  useCommonQuery<{
    AdditionalItems: AdditionalItemReturnType[];
  }>(
    API_PATH.AdditionalItemsGET(boatId!, charterTypeId!),
    !(boatId && charterTypeId)
  );

export const useMultipleAdditionalItems = (
  boatId: number | null,
  charterTypeIds?: number[] | null
) => {
  return useCommonQuery<{
    AdditionalItems: AdditionalItemReturnType[];
  }>(
    API_PATH.MultipleAdditionalItemsGET(boatId!, charterTypeIds ?? []),
    !(boatId && charterTypeIds)
  );
};
