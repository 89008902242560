export const customFetch = async <
  Payload extends Record<string, unknown> = never
>(
  path: string,
  method = "GET",
  bodyPayload?: Payload
) => {
  return fetch(`${import.meta.env.VITE_API_HOST}/${path}`, {
    method,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: bodyPayload ? JSON.stringify(bodyPayload) : undefined,
  });
};
