import { textStyles } from "../../styles/typography";
import { ccn } from "../../styles/utils";

export default function SlotUnavailableMessage() {
  return (
    <div className={ccn(textStyles.sub1, "text-red mt-5")}>
      This charter is fully booked. Please select different{" "}
      <button
        onClick={() => document.getElementById("charterType")?.focus()}
        className="underline"
      >
        Charter Type
      </button>
      .
    </div>
  );
}
