import { type MouseEvent, type PropsWithChildren } from "react";
import { cn } from "../../styles/utils";

const commonStyles =
  "inline-flex items-center transition-colors gap-2 justify-center ";
const filledButtonStyles = "px-6 py-4 rounded-full font-semibold";
const smallButtonStyles = "px-3 py-1 rounded-full font-medium text-sm";
const textButtonStyles = "gap-1";
const variantStyles = {
  primary: {
    enabled:
      "bg-orange-primary text-white hover:bg-orange-dark active:bg-orange-light",
    disabled: "bg-grey-primaryExtra text-grey-light",
  },
  secondary: {
    enabled:
      "bg-grey-light text-blue-dark hover:bg-grey-primary active:bg-grey-light",
    disabled: "bg-white text-blue-50",
  },
  text: {
    enabled:
      "text-blue-500 font-medium leading-5 underline hover:text-blue-600 active:text-blue-700",
    disabled: "text-blue-200 pointer-events-none",
  },
};

type CommonProps = PropsWithChildren<{
  variant: keyof typeof variantStyles;
  icon?: React.ReactNode;
  type?: "button" | "submit" | "link";
  disabled?: boolean;
  className?: string;
  small?: boolean;
  form?: string;
}>;

type TypeProps =
  | {
      type: "button" | "submit";
      href?: never;
      blank?: never;
      onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
    }
  | {
      type?: never;
      href: string;
      blank?: boolean;
      onClick?: (event: MouseEvent<HTMLAnchorElement>) => void;
    };

export default function Button({
  variant,
  icon,
  children,
  disabled,
  type,
  href,
  className,
  blank,
  small,
  form,
  onClick,
}: CommonProps & TypeProps) {
  const computedClassName = cn(
    commonStyles,
    variant === "text" ? textButtonStyles : filledButtonStyles,
    variantStyles[variant][disabled ? "disabled" : "enabled"],
    small && smallButtonStyles,
    className,
    disabled && "pointer-events-none"
  );

  const content = (
    <>
      {icon && <span>{icon}</span>} {children}
    </>
  );

  if (href) {
    return (
      <a
        href={href}
        className={computedClassName}
        onClick={onClick}
        target={blank ? "_blank" : undefined}
      >
        {content}
      </a>
    );
  }

  return (
    <button
      type={type}
      className={computedClassName}
      disabled={disabled}
      onClick={onClick as (event: MouseEvent<HTMLButtonElement>) => void}
      form={form}
    >
      {content}
    </button>
  );
}
