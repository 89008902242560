import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import * as Sentry from "@sentry/react";

import "./index.css";

Sentry.init({
  enabled: import.meta.env.PROD,

  dsn: "https://08c027ebbf33d4d63749fedda6687826@iguana.observer/38",

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
  tracePropagationTargets: [/^\//, import.meta.env.VITE_API_HOST],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
      <App />
    </Sentry.ErrorBoundary>
  </React.StrictMode>
);
