import { useAtomValue, useSetAtom } from "jotai";
import { useOrderCalculation } from "../../api/hooks";
import { CharterTypeReturnType } from "../../api/types";
import {
  convertDurationToHours,
  createTimeIntervals,
  formatSlotTime,
  isSlotUnavailable,
} from "../../utils/date";
import SlotComponent from "./SlotComponent";
import {
  selectedDateAsStringAtom,
  selectedDateAtom,
  boatIdAtom,
  passengersAtom,
  selectedDayUnavailabilitiesAtom,
  selectedCharterTypeAtom,
  selectedCalculationAtom,
  selectedAddonsAtom,
  selectedSlotAtom,
} from "./store";
import { isPast } from "date-fns";
import SlotUnavailableMessage from "./SlotUnavailableMessage";

export default function StandardCharterSlots({
  handleNextStep,
}: {
  handleNextStep: () => void;
}) {
  const selectedDayString = useAtomValue(selectedDateAsStringAtom);
  const selectedDay = useAtomValue(selectedDateAtom);
  const boatId = useAtomValue(boatIdAtom);
  const passengers = useAtomValue(passengersAtom);
  const selectedDayUnavailabilities = useAtomValue(
    selectedDayUnavailabilitiesAtom
  );
  const selectedCharterType = useAtomValue(
    selectedCharterTypeAtom
  ) as CharterTypeReturnType;
  const selectedAddons = useAtomValue(selectedAddonsAtom);
  const setSelectedCalculation = useSetAtom(selectedCalculationAtom);
  const setSelectedSlot = useSetAtom(selectedSlotAtom);

  const { data: calculation, loading } = useOrderCalculation(
    {
      Items: [
        {
          PricingId: selectedCharterType!.Id,
          Passengers: passengers,
          Date: selectedDayString,
          BoatId: boatId!,
          AddonsIds: [],
          AdditionalItemsIds: selectedAddons,
        },
      ],
      Currency: "USD",
    },
    !boatId
  );

  // get all ranges for the day (10-23) using duration
  // for example 1 hour duration => [[10, 11], ... [22, 23]]
  const durationHours = convertDurationToHours(selectedCharterType!.Duration);
  // make intervals from ranges
  const intervals = createTimeIntervals(selectedDay, durationHours);

  // exclude ranges that are in unavailabilities
  const slotsWithAvailability = intervals.map((rentalInterval) => {
    if (!selectedDayUnavailabilities.length) {
      return {
        interval: rentalInterval,
        available: !isPast(rentalInterval.start),
      };
    }
    // check if any unavailability overlaps with the rental interval
    const unavailable = selectedDayUnavailabilities.some((unavailability) => {
      return isSlotUnavailable(unavailability, rentalInterval);
    });
    // filter by unavailability
    return {
      interval: rentalInterval,
      available: !unavailable,
    };
  });

  const allSlotsUnavailable = slotsWithAvailability.every(
    (slot) => !slot.available
  );

  // render single calculation for the whole day, using available intervals
  return (
    <div>
      <ul className="flex flex-col gap-4">
        {slotsWithAvailability.map((slot) => (
          <li key={slot.interval.start.toString()}>
            <SlotComponent
              key={slot.interval.start.toString()}
              startTimeFormatted={formatSlotTime(slot.interval.start)}
              endTimeFormatted={formatSlotTime(slot.interval.end)}
              price={calculation?.TotalAmount}
              handleBook={() => {
                setSelectedCalculation(calculation);
                setSelectedSlot({
                  charterTypeId: selectedCharterType.Id,
                  interval: slot.interval,
                });
                handleNextStep();
              }}
              disabled={!slot.available}
              loading={loading}
            />
          </li>
        ))}
      </ul>
      {allSlotsUnavailable && <SlotUnavailableMessage />}
    </div>
  );
}
