import { useAtomValue, useSetAtom } from "jotai";
import TextArea from "../ui/TextArea";
import TextField from "../ui/TextField";
import { CustomerDetails } from "./types";
import {
  boatIdAtom,
  orderDataAtom,
  customerDetailsAtom,
  passengersAtom,
  orderStatusAtom,
  selectedAddonsAtom,
  selectedDateAsStringAtom,
  selectedSlotAtom,
} from "./store";
import { useOrderMutation } from "../../api/hooks";
import { ccn } from "../../styles/utils";
import { textStyles } from "../../styles/typography";
import { customFetch } from "../../api/common";
import { API_PATH } from "../../api/paths";
import { isSlotUnavailable, removeTimezoneFromISO } from "../../utils/date";
import { UnavailabilityReturnType } from "../../api/types";
import { formatISO } from "date-fns";

export default function CustomerDetailsForm({
  setIsPaymentReady,
}: {
  setIsPaymentReady: (isReady: boolean) => void;
}) {
  const selectedDayString = useAtomValue(selectedDateAsStringAtom);
  const boatId = useAtomValue(boatIdAtom);
  const passengers = useAtomValue(passengersAtom);
  const selectedAddons = useAtomValue(selectedAddonsAtom);
  const selectedSlot = useAtomValue(selectedSlotAtom);
  const setCustomerDetails = useSetAtom(customerDetailsAtom);
  const setOrderData = useSetAtom(orderDataAtom);
  const setOrderStatus = useSetAtom(orderStatusAtom);

  const { mutate: orderMutate } = useOrderMutation();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const data = Object.fromEntries(formData) as CustomerDetails;
    setCustomerDetails(data);

    if (!selectedSlot) {
      return;
    }

    try {
      // Check unavailabilities before creating order
      const res = await customFetch(
        API_PATH.BoatUnavailabilityGET(
          boatId!,
          selectedDayString,
          selectedDayString
        )
      );
      if (!res.ok) {
        throw new Error("Failed to fetch unavailabilities");
      }
      const unavailabilities = ((await res.json())?.Unavailabilities ??
        []) as UnavailabilityReturnType[];
      const slotUnavailable = unavailabilities.some((unavailability) =>
        isSlotUnavailable(unavailability, selectedSlot.interval)
      );
      if (slotUnavailable) {
        setOrderStatus("SLOT_BOOKED");
        return;
      }

      // Create order
      const orderData = await orderMutate({
        Items: [
          {
            PricingId: selectedSlot.charterTypeId,
            Passengers: passengers,
            // TODO if Period=Day => selectedDayString, note: currently we do not have day periods
            // this.selectedCharterType && this.selectedCharterType.Period === 'Day' ? this.selectedDate.dateString : this.selectedCharterTime,
            // Replace local timezone with UTC
            // accepts "YYYY-MM-DDTHH:mm:ss"
            Date: removeTimezoneFromISO(formatISO(selectedSlot?.interval.start)) || selectedDayString,
            BoatId: boatId!,
            AddonsIds: [],
            AdditionalItemsIds: selectedAddons,
          },
        ],
        Currency: "USD",
        Email: data.email,
        Name: data.fullName,
        Phone: data.phone,
        Note: data.message,
        Passengers: passengers,
        Budget: "Undisclosed",
      });

      if (!orderData) {
        throw new Error("Order data is empty");
      }
      setOrderData(orderData);
      setIsPaymentReady(true);
    } catch (error) {
      setOrderStatus("PAYMENT_FAILED");
      console.error(error);
    }
  };

  return (
    <div>
      <h2
        className={ccn(
          textStyles.h2,
          "text-blue-dark60 md:mb-10 mb-4 text-center"
        )}
      >
        Add Your Contact Info
      </h2>
      <form
        className="flex flex-col gap-3"
        onSubmit={handleSubmit}
        id="contactInformation"
      >
        <TextField placeholder="Full Name" name="fullName" required />

        <TextField
          placeholder="E-mail @ Address"
          name="email"
          type="email"
          pattern="[A-Za-z0-9._+\-']+@[A-Za-z0-9.\-]+\.[A-Za-z]{2,}"
          required
        />

        <TextField
          placeholder="Phone Number"
          name="phone"
          type="tel"
          onChange={(e) => {
            const pattern =
              /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{3,4})(?: *x(\d+))?\s*$/;
            const isValid = pattern.test(e.target.value);

            if (!isValid) {
              e.target.setCustomValidity("Invalid phone number");
            } else {
              e.target.setCustomValidity("");
            }
          }}
          required
        />

        <TextArea
          placeholder="Note or Special Request (optional)"
          name="message"
          rows={1}
        />
      </form>
    </div>
  );
}
