import { useAtomValue } from "jotai";
import {
  boatAtom,
  passengersAtom,
  selectedCalculationAtom,
  selectedDateAtom,
  selectedSlotAtom,
} from "./store";
import { formatDate, formatSlotTime } from "../../utils/date";
import { formatCurrency } from "../../utils/currency";
import { textStyles } from "../../styles/typography";
import { ccn } from "../../styles/utils";

// const notGratuityIncludedBoatIds = [
//   5172, 5614, 4972, 10403, 7258, 9868, 9122, 3722, 7259, 4973, 5808, 5734, 5652,
// ];

export default function BookingSummary() {
  const selectedCalculation = useAtomValue(selectedCalculationAtom);
  const passengers = useAtomValue(passengersAtom);
  const selectedDate = useAtomValue(selectedDateAtom);
  const selectedSlot = useAtomValue(selectedSlotAtom);
  const boat = useAtomValue(boatAtom);

  if (!selectedCalculation || !boat || !selectedSlot) {
    return null;
  }

  // const showGratuityInfo =
  //   notGratuityIncludedBoatIds.includes(boat!.Id) ||
  //   selectedCalculation.OrderItems.filter((item) => {
  //     return item.Label == "Gratuity" && item.TotalAmount == 0;
  //   }).length > 0;

  return (
    <div>
      <h2 className={ccn(textStyles.h2, "text-blue-dark text-center md:mb-10 mb-4")}>
        Booking Summary
      </h2>
      <ul className="flex flex-col gap-3">
        <li className="flex justify-between">
          <div className={ccn(textStyles.body1, "text-blue-dark60")}>
            Charter
          </div>{" "}
          <div className={ccn(textStyles.sub1, "text-blue-dark text-right")}>
            {boat?.Label}
            <br /> {passengers} Passengers
          </div>
        </li>

        <li className="flex justify-between text-right border-b border-grey-primary pb-3">
          <div className={ccn(textStyles.body1, "text-blue-dark60")}>Date</div>{" "}
          <div className={ccn(textStyles.sub1, "text-blue-dark")}>
            {formatDate(selectedDate)} <br />{" "}
            {formatSlotTime(selectedSlot?.interval.start)} —{" "}
            {formatSlotTime(selectedSlot?.interval.end)}
          </div>
        </li>

        {selectedCalculation?.OrderItems.map((item) => (
          <li key={item.Label} className="flex justify-between">
            <div className={ccn(textStyles.body1, "text-blue-dark60")}>
              {item.Label}
            </div>{" "}
            <div className={ccn(textStyles.sub1, "text-blue-dark")}>
              {formatCurrency(item.TotalAmount)}
            </div>
          </li>
        ))}

        <li className="flex justify-between border-t border-grey-primary pt-3">
          <div className={ccn(textStyles.h2, "text-blue-dark60")}>
            Total Amount
          </div>{" "}
          <div className={ccn(textStyles.h2, "text-blue-light")}>
            {formatCurrency(selectedCalculation?.TotalAmount)}
          </div>
        </li>
      </ul>
      {/* TODO ???? */}
      {/* {showGratuityInfo && "* Gratuity not included"} */}
    </div>
  );
}
