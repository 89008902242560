import { type ComponentPropsWithoutRef } from "react";

// import { textStyles } from "~/styles/typography";
import { ccn } from "../../styles/utils";
import { commonStyles, variantStyles } from "./TextField";

type Props = {
  variant?: keyof typeof variantStyles;
} & ComponentPropsWithoutRef<"textarea">;

export default function TextArea({
  variant = "light",
  rows = 5,
  ...props
}: Props) {
  return (
    <textarea
      {...props}
      rows={rows}
      className={ccn(
        // textStyles.sub2,
        commonStyles,
        variantStyles[variant].enabled,
        props.className,
      )}
    />
  );
}
