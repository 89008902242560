import {
  addHours,
  areIntervalsOverlapping,
  format,
  setHours,
  setMinutes,
  setSeconds,
} from "date-fns";
import { CharterTypeReturnType, UnavailabilityReturnType } from "../api/types";
import { interval } from "date-fns/interval";
import { Interval } from "date-fns";
import { isPast } from "date-fns";

export const formatDate = (date: Date) => {
  // returns format: May 9, 2024
  return date.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });
};

export const formatDateToApi = (date: Date) => {
  // returns format: 2024-05-09
  return format(date, "yyyy-MM-dd");
};

const numToDoubleDigit = (num: number) => {
  return num < 10 ? `0${num}` : num;
};

export const formatSlotTime = (date: Date) => {
  return format(date, "h:mm a");
};

export const getPartDaySlotDates = (
  charterType: CharterTypeReturnType,
  // format 2024-05-09
  selectedDate: string
) => {
  const startDate = new Date(
    `${selectedDate}T${numToDoubleDigit(
      charterType.StartTime!.Hours
    )}:${numToDoubleDigit(charterType.StartTime!.Minutes)}:${numToDoubleDigit(
      charterType.StartTime!.Seconds
    )}`
  );

  const durationNumber = convertDurationToHours(charterType.Duration);
  const endDate = addHours(startDate, durationNumber);

  return {
    startDate,
    endDate,
    startTimeFormatted: formatSlotTime(startDate),
    endTimeFormatted: formatSlotTime(endDate),
    slotInterval: interval(startDate, endDate),
  };
};

// e.g. duration: "01:30:00"
export function convertDurationToHours(duration: string) {
  // Split the duration string into hours, minutes, and seconds
  const [hours, minutes, seconds] = duration.split(":").map(Number);

  // Calculate the total hours as a number
  return hours + minutes / 60 + seconds / 3600;
}

// creates time intervals from startHour to endHour with durationHours
export function createTimeIntervals(
  currentDate = new Date(),
  durationHours: number,
  startHour = 10,
  endHour = 23
) {
  // Initialize the start time and end time
  let startTime = setSeconds(
    setMinutes(setHours(currentDate, startHour), 0),
    0
  );
  const endTime = setSeconds(setMinutes(setHours(currentDate, endHour), 0), 0);

  const timeIntervals = [];

  // Loop to generate time ranges
  while (startTime <= endTime) {
    const nextTime = addHours(startTime, durationHours);
    if (nextTime > endTime) break;

    // Format the time range and add to the list
    timeIntervals.push(interval(startTime, nextTime));
    startTime = nextTime;
  }

  return timeIntervals;
}

export function isSlotUnavailable(
  unavailability: UnavailabilityReturnType,
  slotInterval: Interval
) {
  return (
    isPast(slotInterval.start) ||
    areIntervalsOverlapping(
      interval(
        removeTimezoneFromISO(unavailability.From),
        removeTimezoneFromISO(unavailability.Until)
      ),
      slotInterval
    )
  );
}

export function removeTimezoneFromISO(dateString: string) {
  // Match the part of the ISO string before the timezone information
  // The regex captures the date and time part of the ISO string
  const result = dateString.match(
    /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)?)/
  );
  return result ? result[1] : dateString;
}
