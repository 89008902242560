import { atom } from "jotai";
import { loadable } from "jotai/utils";
import {
  convertDurationToHours,
  createTimeIntervals,
  formatDateToApi,
  getPartDaySlotDates,
  isSlotUnavailable,
} from "../../utils/date";
import { CustomerDetails } from "./types";
import {
  BoatReturnType,
  CalculationReturnType,
  CharterTypeReturnType,
  ConfirmTransactionReturnType,
  CustomPartDayCharterType,
  OrderReturnType,
  UnavailabilityReturnType,
} from "../../api/types";
import { customFetch } from "../../api/common";
import { API_PATH } from "../../api/paths";
import { endOfMonth, isSameDay, isSameMonth, startOfMonth } from "date-fns";
import { interval } from "date-fns/interval";

export const boatIdAtom = atom<number | null>(null);
export const passengersAtom = atom<number>(1);
export const selectedCharterTypeAtom = atom<
  CharterTypeReturnType | CustomPartDayCharterType | null
>(null);
export const customerDetailsAtom = atom<CustomerDetails | null>(null);
export const currentCalendarMonthDateAtom = atom<Date>(new Date());
export const selectedDateAtom = atom<Date>(new Date());
export const selectedDateAsStringAtom = atom<string>((get) =>
  formatDateToApi(get(selectedDateAtom))
);
export const selectedSlotAtom = atom<{
  charterTypeId: number;
  interval: ReturnType<typeof interval>;
} | null>(null);
export const selectedAddonsAtom = atom<number[]>([]);
export const selectedCalculationAtom = atom<CalculationReturnType | null>(null);

export const orderDataAtom = atom<OrderReturnType | null>(null);
export const orderStatusAtom = atom<
  | "PAYMENT_FAILED"
  | "SUCCESS"
  | "PENDING"
  | "SLOT_BOOKED"
  | "INTENT_CREATION_FAILED"
  | null
>(null);
export const paymentResponseAtom = atom<ConfirmTransactionReturnType | null>(
  null
);

export const selectedDayUnavailabilitiesAtom = atom<UnavailabilityReturnType[]>(
  (get) => {
    const day = get(selectedDateAtom);
    const boatUnavailabilityLoadable = get(boatUnavailabilityLoadableAtom);
    const unavailabilities =
      "data" in boatUnavailabilityLoadable
        ? boatUnavailabilityLoadable.data.Unavailabilities
        : null;

    if (!unavailabilities) {
      return [];
    }
    const selectedUnavailabilities = unavailabilities?.filter(
      (unavailability) => {
        const unavailableTimeStart = new Date(unavailability.From);
        return isSameDay(unavailableTimeStart, day);
      }
    );

    return selectedUnavailabilities ?? [];
  }
);

export const boatAtom = atom(async (get) => {
  const boatId = get(boatIdAtom);

  if (!boatId) {
    throw new Error("Boat ID is not set");
  }
  const res = await customFetch(API_PATH.BoatGET(boatId));
  if (!res.ok) {
    throw new Error("Failed to fetch boat");
  }
  return (await res.json()) as BoatReturnType;
});
export const boatLoadableAtom = loadable(boatAtom);

export const boatCharterTypesAtom = atom(async (get) => {
  const boatId = get(boatIdAtom);
  const selectedDate = get(selectedDateAsStringAtom);

  if (!boatId || !selectedDate) {
    throw new Error("Boat ID or date is not set");
  }
  const res = await customFetch(
    API_PATH.BoatCharterTypesGET(boatId, selectedDate)
  );
  if (!res.ok) {
    throw new Error("Failed to fetch charter types");
  }
  const charterTypes = (
    (await res.json()) as { CharterTypes: CharterTypeReturnType[] }
  ).CharterTypes;

  const standard = charterTypes?.filter((type) => !type.HasStartTimeSet);
  const part = charterTypes?.filter((type) => type.HasStartTimeSet);
  const charterTypesFinal = part?.length
    ? [
        {
          Id: "part",
          Label: "Part Day",
          Hours: [...part],
        } as CustomPartDayCharterType,
        ...(standard ?? []),
      ]
    : [...(standard ?? [])];

  return charterTypesFinal;
});
export const boatCharterTypesLoadableAtom = loadable(boatCharterTypesAtom);

export const boatUnavailabilityAtom = atom(async (get) => {
  const boatId = get(boatIdAtom);

  if (!boatId) {
    throw new Error("Boat ID or date is not set");
  }
  const today = new Date();
  const calendarMonthDate = get(currentCalendarMonthDateAtom);
  const from = isSameMonth(today, calendarMonthDate)
    ? formatDateToApi(today)
    : formatDateToApi(startOfMonth(calendarMonthDate));

  const until = formatDateToApi(endOfMonth(calendarMonthDate));

  const res = await customFetch(
    API_PATH.BoatUnavailabilityGET(boatId, from, until)
  );
  if (!res.ok) {
    throw new Error("Failed to fetch unavailability");
  }
  return (await res.json()) as { Unavailabilities: UnavailabilityReturnType[] };
});
export const boatUnavailabilityLoadableAtom = loadable(boatUnavailabilityAtom);

type BookedDay = ["disabled" | "partial", Date];
export const bookedDaysAtom = atom<BookedDay[]>((get) => {
  const boatUnavailabilityLoadable = get(boatUnavailabilityLoadableAtom);
  const unavailabilities =
    "data" in boatUnavailabilityLoadable
      ? boatUnavailabilityLoadable.data.Unavailabilities
      : null;

  const charterTypesLoadable = get(boatCharterTypesLoadableAtom);
  const charterTypes =
    "data" in charterTypesLoadable ? charterTypesLoadable.data : [];

  if (!unavailabilities || !charterTypes.length) {
    return [];
  }

  const slots =
    (
      charterTypes.find(
        (type) => type.Id === "part"
      ) as CustomPartDayCharterType
    )?.Hours ?? [];

  const dynamicSlots =
    (charterTypes.filter(
      (type) => type.Id !== "part"
    ) as CharterTypeReturnType[]) ?? [];

  if (!slots.length) {
    return [];
  }

  // first group unavailable slots by day
  // [day: string]: UnavailabilityReturnType[]
  const unavailabilitiesByDay = unavailabilities.reduce(
    (acc, unavailability) => {
      const day = formatDateToApi(new Date(unavailability.From));
      if (!acc[day]) {
        acc[day] = [];
      }
      acc[day].push(unavailability);
      return acc;
    },
    {} as Record<string, UnavailabilityReturnType[]>
  );

  // then check if all slots are unavailable
  const dayUnavailabilities = Object.entries(unavailabilitiesByDay).map(
    ([day, unavailabilities]) => {
      const unavailableTimeStart = new Date(day + "T00:00:00");

      // day is unavailable if all slots are unavailable
      const partDayUnavailable = slots.every((slot) => {
        const { slotInterval } = getPartDaySlotDates(slot, day);
        return unavailabilities.some((unavailability) => {
          return isSlotUnavailable(unavailability, slotInterval);
        });
      });

      const dynamicUnavailable = dynamicSlots.every((charterType) => {
        // get all ranges for the day (10-23) using duration
        const durationHours = convertDurationToHours(charterType!.Duration);
        // make intervals from ranges
        const intervals = createTimeIntervals(
          unavailableTimeStart,
          durationHours
        );
        // check with unavailabities
        return intervals.every((interval) => {
          return unavailabilities.some((unavailability) => {
            return isSlotUnavailable(unavailability, interval);
          });
        });
      });

      const wholeDayUnavailable = partDayUnavailable && dynamicUnavailable;

      return [
        wholeDayUnavailable ? "disabled" : "partial",
        unavailableTimeStart,
      ] as BookedDay;
    }
  );

  return dayUnavailabilities;
});
