import { useAtomValue } from "jotai";
import { selectedCharterTypeAtom, selectedDateAtom } from "./store";
import PartDayCharterSlots from "./PartDayCharterSlots";
import StandardCharterSlots from "./StandardCharterSlots";
import { textStyles } from "../../styles/typography";
import { ccn } from "../../styles/utils";
import { formatDate } from "../../utils/date";

type Props = {
  handleNextStep: () => void;
};

export default function AvailableSlots({ handleNextStep }: Props) {
  const selectedCharterType = useAtomValue(selectedCharterTypeAtom);

  if (!selectedCharterType) {
    return null;
  }

  return (
    <div>
      <div className="flex items-center justify-between mb-5">
        <div className={ccn(textStyles.sub1, "text-blue-dark60")}>
          Choose Your Time
        </div>
        <BoatSlotInfo />
      </div>
      {selectedCharterType?.Id === "part" ? (
        <PartDayCharterSlots handleNextStep={handleNextStep} />
      ) : (
        // Fetch one slot and render the whole day
        <StandardCharterSlots handleNextStep={handleNextStep} />
      )}
    </div>
  );
}

const BoatSlotInfo = () => {
  // const boat = useAtomValue(boatAtom);
  const selectedDay = useAtomValue(selectedDateAtom);
  // const selectedSlot = useAtomValue(selectedSlotAtom);

  return (
    <div
      className={ccn(
        textStyles.h2,
        "flex flex-col gap-3 md:gap-0 md:flex-row justify-between"
      )}
    >
      {/* <div className="text-blue-dark">{boat?.Label}</div> */}
      <div className="text-blue-light">
        {formatDate(selectedDay)}
        {/* {selectedSlot && (
          <>
            ; {formatSlotTime(selectedSlot?.interval.start)} —{" "}
            {formatSlotTime(selectedSlot?.interval.end)}
          </>
        )} */}
      </div>
    </div>
  );
};
