import { SVGProps, type ComponentPropsWithoutRef } from "react";

import { ccn } from "../../styles/utils";
import { commonStyles, variantStyles } from "./TextField";

type SelectProps = {
  variant?: keyof typeof variantStyles;
} & ComponentPropsWithoutRef<"select">;

export default function Select({
  variant = "light",
  className,
  ...props
}: SelectProps) {
  return (
    <span className="relative w-full">
      <select
        {...props}
        className={ccn(
          "w-full appearance-none pr-10 text-ellipsis overflow-hidden whitespace-nowrap",
          commonStyles,
          variantStyles[variant].enabled,
          className
        )}
      />
      <SelectDownIcon className="absolute right-5 top-1/2 -translate-y-1/2" />
    </span>
  );
}

const SelectDownIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill="#2A3D8C"
      d="m16.692 7.942-6.25 6.25a.623.623 0 0 1-.884 0l-6.25-6.25a.625.625 0 0 1 .884-.884L10 12.866l5.808-5.808a.625.625 0 1 1 .884.884Z"
    />
  </svg>
);
