import { type ElementType, type PropsWithChildren } from "react";
import clsx from "clsx";

export default function Stack({
  children,
  className,
  as,
  inlineStyles = {},
}: PropsWithChildren<{
  className?: string;
  as?: ElementType;
  inlineStyles?: Record<string, string>;
}>) {
  const Component = as ?? "div";
  return (
    <Component
      className={clsx("flex flex-col", className)}
      style={inlineStyles}
    >
      {children}
    </Component>
  );
}
