import { PropsWithChildren } from "react";
import { textStyles } from "../../styles/typography";
import { ccn } from "../../styles/utils";

type Props = PropsWithChildren<{
    label: string;
    htmlFor: string;
    labelClassName?: string;
}>;

// just adds a label to the input
export default function AddFloatingLabel({ htmlFor, label, children, labelClassName }: Props) {
  return (
    <div className="flex-1 flex flex-col items-start isolate">
      <label
        htmlFor={htmlFor}
        className={ccn(
          textStyles.label,
          "text-blue-dark60 bg-grey-light relative -bottom-2 px-1 ml-3 z-10",
          labelClassName
        )}
      >
        {label}
      </label>
      {children}
    </div>
  );
}
