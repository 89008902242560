export const API_PATH = {
  BoatGET: (id: number) => `api/Boats/${id}`,
  BoatUnavailabilityGET: (id: number, from: string, until: string) =>
    `api/Boats/${id}/Unavailability?from=${from}&until=${until}`,
  BoatCharterTypesGET: (id: number, date: string) =>
    `api/Boats/${id}/CharterTypes?date=${date}&preferredCurrency=USD`,
  OrderCalculationPOST: () => `api/Order/Calculation`,
  OrderPOST: () => `api/Checkout/create-checkout-session`,
  PaymentStatusGET: (orderIntentId: string) =>
    `api/Checkout/payment-status?orderIntentId=${orderIntentId}`,
  MultipleAdditionalItemsGET: (boatId: number, charterTypeIds: number[]) =>
    `api/Boats/${boatId}/MultipleAdditionalItems?pricingIds=${charterTypeIds.join(
      "&pricingIds="
    )}`,
  AdditionalItemsGET: (boatId: number, charterTypeId: number) =>
    `api/Boats/${boatId}/AdditionalItems?pricingId=${charterTypeId}`,
};
