import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { StripeElementsOptions, loadStripe } from "@stripe/stripe-js";
import { FormEvent, useEffect, useState } from "react";
import { useSetAtom } from "jotai";
import { orderStatusAtom } from "./store";
import { ccn } from "../../styles/utils";
import { textStyles } from "../../styles/typography";

const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY, {
  locale: "en",
});

type Props = {
  clientSecret: string;
  setIsPaymentReady: (isReady: boolean) => void;
};

export default function StripePaymentForm({
  clientSecret,
  setIsPaymentReady,
}: Props) {
  const options: StripeElementsOptions = {
    // passing the client secret obtained from the server
    clientSecret: clientSecret,
    appearance: {
      rules: {
        ".Label": {
          fontWeight: "500",
          fontSize: "16px",
          color: "rgba(42, 61, 140, 0.6)",
        },
        ".Input": {
          fontSize: "16px",
          color: "rgba(42, 61, 140)",
          border: "1px solid rgba(42, 61, 140, 0.2)",
          borderRadius: "8px",
          padding: "16px 20px",
        },
        ".Input::placeholder": {
          color: "rgba(42, 61, 140, 0.6)",
        },
      },
      variables: {
        fontFamily:
          'Montserrat, ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
      },
    },
    loader: "always",
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <FormWithPaymentElement setIsPaymentReady={setIsPaymentReady} />
    </Elements>
  );
}

const FormWithPaymentElement = ({
  setIsPaymentReady,
}: {
  setIsPaymentReady: (isReady: boolean) => void;
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const setOrderStatus = useSetAtom(orderStatusAtom);

  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  useEffect(() => {
    // Set up Stripe.js and Elements to use in payment form
    if (stripe) {
      setIsPaymentReady(true);
    }
  }, [stripe]);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const { error } = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      redirect: "if_required",
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
      // on successful payment, set the payment status to PENDING and call GET/api/Checkout/payment-status
      setOrderStatus("PENDING");
    }
  };

  return (
    <form onSubmit={handleSubmit} id="stripePaymentForm">
      <h2
        className={ccn(
          textStyles.h2,
          "text-blue-dark60 md:mb-10 mb-4 text-center"
        )}
      >
        Payment Information
      </h2>
      <PaymentElement
        onReady={(e) => {
          e.focus();
        }}
      />
      {/* Show error message to your customers */}
      {errorMessage && <div className="mt-5 text-red">{errorMessage}</div>}
    </form>
  );
};
