import { useAtom, useAtomValue } from "jotai";
import Select from "../ui/Select";
import { boatCharterTypesLoadableAtom, selectedCharterTypeAtom } from "./store";
import { useEffect, useState } from "react";
import {
  CharterTypeReturnType,
  CustomPartDayCharterType,
} from "../../api/types";
import AddFloatingLabel from "../ui/AddFloatingLabel";

export default function CharterTypeSelect() {
  const [prevCharterTypes, setPrevCharterTypes] = useState<
    (CharterTypeReturnType | CustomPartDayCharterType)[]
  >([]);
  const [selectedCharterType, setSelectedCharterType] = useAtom(
    selectedCharterTypeAtom
  );

  const boatCharterTypesLoadable = useAtomValue(boatCharterTypesLoadableAtom);
  const charterTypesLoading = boatCharterTypesLoadable.state === "loading";
  const charterTypes =
    "data" in boatCharterTypesLoadable ? boatCharterTypesLoadable.data : [];

  useEffect(() => {
    // set the previous charter types when they are loaded
    if (!charterTypesLoading) {
      setPrevCharterTypes(charterTypes);
    }
  }, [charterTypesLoading]);

  useEffect(() => {
    // if the charter types are loaded it needs to be reselected
    if (!charterTypesLoading && prevCharterTypes?.length > 0) {
      // try to persist the selected charter type
      const newCharterType = prevCharterTypes?.find(
        (type) => type.Id === selectedCharterType?.Id
      );
      setSelectedCharterType(newCharterType ?? prevCharterTypes?.[0]);
    }
  }, [charterTypesLoading, prevCharterTypes]);

  // // if there is only one charter type, don't show the select
  if (prevCharterTypes?.length === 1) {
    return null;
  }

  return (
    <AddFloatingLabel label="Charter Type" htmlFor="charterType">
      <Select
        id="charterType"
        variant="dark"
        value={selectedCharterType?.Id}
        onChange={(e) => {
          const selectedCharterType = prevCharterTypes?.find(
            (type) => String(type.Id) === e.target.value
          );
          setSelectedCharterType(selectedCharterType ?? null);
        }}
        className="w-full"
        disabled={charterTypesLoading}
      >
        {prevCharterTypes?.map((charterType) => (
          <option key={charterType.Id} value={charterType.Id}>
            {charterType.Label}
          </option>
        ))}
      </Select>
    </AddFloatingLabel>
  );
}
