import { type SVGProps } from "react";

export const InstagramIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#0A1D37"
      d="M7.68 1.44a6.246 6.246 0 0 0-6.24 6.24v8.64a6.246 6.246 0 0 0 6.24 6.24h8.64a6.246 6.246 0 0 0 6.24-6.24V7.68a6.246 6.246 0 0 0-6.24-6.24H7.68Zm10.08 3.84c.528 0 .96.432.96.96s-.432.96-.96.96a.963.963 0 0 1-.96-.96c0-.528.432-.96.96-.96ZM12 6.72A5.283 5.283 0 0 1 17.28 12 5.283 5.283 0 0 1 12 17.28 5.283 5.283 0 0 1 6.72 12 5.283 5.283 0 0 1 12 6.72Zm0 .96c-2.38 0-4.32 1.94-4.32 4.32 0 2.38 1.94 4.32 4.32 4.32 2.38 0 4.32-1.94 4.32-4.32 0-2.38-1.94-4.32-4.32-4.32Z"
    />
  </svg>
);

export const LinkedInIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#0A1D37"
      d="M19.68 1.92H4.32a2.4 2.4 0 0 0-2.4 2.4v15.36a2.4 2.4 0 0 0 2.4 2.4h15.36a2.4 2.4 0 0 0 2.4-2.4V4.32a2.4 2.4 0 0 0-2.4-2.4ZM8.16 9.6v9.12H5.28V9.6h2.88ZM5.28 6.946c0-.672.576-1.186 1.44-1.186s1.406.514 1.44 1.186c0 .672-.538 1.214-1.44 1.214-.864 0-1.44-.542-1.44-1.214ZM18.72 18.72h-2.88v-4.8c0-.96-.48-1.92-1.68-1.94h-.039c-1.161 0-1.641.99-1.641 1.94v4.8H9.6V9.6h2.88v1.229s.926-1.23 2.789-1.23c1.905 0 3.45 1.311 3.45 3.966v5.155Z"
    />
  </svg>
);

export const FacebookIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#0A1D37"
      d="M19.68 1.92H4.32a2.4 2.4 0 0 0-2.4 2.4v15.36a2.4 2.4 0 0 0 2.4 2.4h15.36a2.4 2.4 0 0 0 2.4-2.4V4.32a2.4 2.4 0 0 0-2.4-2.4Zm-1.92 7.2h-.96c-1.027 0-1.44.24-1.44.96v1.44h2.4l-.48 2.4h-1.92v7.2h-2.4v-7.2h-1.92v-2.4h1.92v-1.44c0-1.92.96-3.36 2.88-3.36 1.392 0 1.92.48 1.92.48v1.92Z"
    />
  </svg>
);

export const CloseIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      stroke="#222"
      strokeLinecap="square"
      strokeLinejoin="round"
      d="M18 6 6 18M6 6l12 12"
    />
  </svg>
);

export const ScrewIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="#778C95"
      fillRule="evenodd"
      d="M26.622 5.596c-.958-2.35-4.286-2.35-5.244 0a6.499 6.499 0 0 1-8.537 3.536c-2.34-.984-4.693 1.37-3.709 3.709a6.499 6.499 0 0 1-3.536 8.537c-2.35.958-2.35 4.286 0 5.244a6.499 6.499 0 0 1 3.536 8.537c-.984 2.34 1.37 4.693 3.709 3.709a6.499 6.499 0 0 1 8.537 3.536c.958 2.35 4.286 2.35 5.244 0a6.499 6.499 0 0 1 8.537-3.536c2.34.984 4.693-1.37 3.709-3.709a6.499 6.499 0 0 1 3.536-8.537c2.35-.958 2.35-4.286 0-5.244a6.499 6.499 0 0 1-3.536-8.537c.984-2.34-1.37-4.693-3.709-3.709a6.499 6.499 0 0 1-8.537-3.536ZM35 24c0 6.075-4.925 11-11 11s-11-4.925-11-11 4.925-11 11-11 11 4.925 11 11ZM19.68 4.904c1.579-3.872 7.061-3.872 8.64 0a4.665 4.665 0 0 0 6.129 2.538c3.854-1.62 7.73 2.255 6.109 6.11a4.665 4.665 0 0 0 2.538 6.128c3.872 1.579 3.872 7.061 0 8.64a4.665 4.665 0 0 0-2.538 6.129c1.62 3.854-2.255 7.73-6.11 6.109a4.665 4.665 0 0 0-6.128 2.538c-1.579 3.872-7.061 3.872-8.64 0a4.665 4.665 0 0 0-6.129-2.538c-3.854 1.62-7.73-2.255-6.109-6.11a4.665 4.665 0 0 0-2.538-6.128c-3.872-1.579-3.872-7.061 0-8.64a4.665 4.665 0 0 0 2.538-6.129c-1.62-3.854 2.255-7.73 6.11-6.109a4.665 4.665 0 0 0 6.128-2.538ZM33.167 24a9.167 9.167 0 1 1-18.334 0 9.167 9.167 0 0 1 18.334 0Z"
      clipRule="evenodd"
    />
    <circle cx={24} cy={24} r={6} fill="#778C95" />
  </svg>
);

export const OilCanisterIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="#778C95"
      d="M44 41.8c0 3.325-2.4 4.2-4 4.2s-4-.875-4-4.2c0-3.866 4-9.8 4-9.8s4 5.934 4 9.8Z"
    />
    <path
      fill="#778C95"
      fillRule="evenodd"
      d="M16.707 2.293a1 1 0 1 0-1.414 1.414L20.985 9.4 5.235 25.15a4.217 4.217 0 0 0 0 5.965l11.651 11.65a4.217 4.217 0 0 0 5.964 0L40 25.616 28.837 14.452a1.004 1.004 0 0 0-.13-.16l-12-12Zm20.31 23.322L22.386 10.982 6.726 26.641a2.13 2.13 0 0 0-.163.182l30.343-1.096.112-.112Z"
      clipRule="evenodd"
    />
  </svg>
);

export const FileIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      stroke="#778C95"
      strokeLinecap="round"
      strokeWidth={2}
      d="M17 25h14M17 31h8"
    />
    <path
      stroke="#778C95"
      strokeWidth={2}
      d="M11 9.4c0-.84 0-1.26.164-1.581a1.5 1.5 0 0 1 .655-.656C12.139 7 12.559 7 13.4 7h12.606c.367 0 .55 0 .723.041.153.037.3.098.433.18.152.093.282.223.54.482l8.595 8.594c.26.26.39.39.482.54.082.135.143.281.18.434.041.173.041.356.041.723V38.6c0 .84 0 1.26-.163 1.581a1.5 1.5 0 0 1-.656.656c-.32.163-.74.163-1.581.163H13.4c-.84 0-1.26 0-1.581-.163a1.5 1.5 0 0 1-.655-.656C11 39.861 11 39.441 11 38.6V9.4Z"
    />
    <path
      fill="#778C95"
      stroke="#778C95"
      strokeWidth={2}
      d="M25 15.6V8.374c0-.046 0-.135.007-.216v-.005c.006-.067.031-.362.257-.626M25 15.6l.264-8.073M25 15.6v.036c0 .39 0 .74.024 1.03.025.313.083.644.248.969a2.5 2.5 0 0 0 1.093 1.092c.325.166.656.224.968.25.292.023.642.023 1.03.023h7.263c.046 0 .135 0 .216-.007h.005c.067-.006.362-.031.626-.257a1.1 1.1 0 0 0 .382-.922M25 15.6l.264-8.073m0 0a1.1 1.1 0 0 1 .922-.382c.346.027.573.218.624.261l.004.004c.062.052.125.115.158.147l.008.009 9.454 9.454.009.008c.032.033.095.096.147.158l.004.004c.043.051.234.278.261.624m0 0-.997.078.997-.078Z"
    />
  </svg>
);

export const MooringIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="#778C95"
      fillRule="evenodd"
      d="M27.75 7.625a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Zm-2.816 5.548A5.627 5.627 0 0 0 24 2a5.625 5.625 0 0 0-.934 11.173.984.984 0 0 0-.003.077v3.779C15.215 17.512 9 24.03 9 32c0 8.284 6.716 15 15 15 4.113 0 7.84-1.656 10.55-4.337l.057-.056.056-.057A14.952 14.952 0 0 0 39 32c0-7.97-6.215-14.488-14.063-14.971V13.25a.984.984 0 0 0-.003-.077ZM24 18.875c7.249 0 13.125 5.876 13.125 13.125h-26.25c0-7.249 5.876-13.125 13.125-13.125Z"
      clipRule="evenodd"
    />
  </svg>
);

export const SelectDownIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      fill="#0A1D37"
      d="M9.808 12.27 6.927 8.812A.8.8 0 0 1 7.54 7.5h4.918a.8.8 0 0 1 .614 1.312l-2.881 3.458a.25.25 0 0 1-.384 0Z"
    />
  </svg>
);

export const PlusIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path stroke="currentColor" strokeLinecap="round" d="M10 5v10M15 10H5" />
  </svg>
);

export const SearchIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 52 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={23.8334} cy={23.8333} r={13} stroke="#0A1D37" />
    <path
      d="M43.3334 43.3333L36.8334 36.8333"
      stroke="#0A1D37"
      strokeLinecap="round"
    />
  </svg>
);

export const ClearIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17}
    height={16}
    fill="none"
    {...props}
  >
    <path
      stroke="#F13838"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m12.25 4-8 8M4.25 4l8 8"
    />
  </svg>
);

export const CheckIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <circle cx={10} cy={10} r={7.5} stroke="currentColor" strokeWidth={2} />
    <path stroke="currentColor" strokeWidth={2} d="m6.667 10 2.5 2.5 4.167-5" />
  </svg>
);

export const AngleLeftIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      stroke="#0A1D37"
      strokeLinecap="round"
      strokeWidth={2}
      d="m15 6-6 6 6 6"
    />
  </svg>
);

export const FileIconSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <path
      stroke="#0A1D37"
      strokeWidth={1.5}
      d="M4.583 5.317c0-.84 0-1.26.163-1.581a1.5 1.5 0 0 1 .656-.656c.32-.163.74-.163 1.581-.163h3.273c.366 0 .55 0 .723.041.153.037.299.097.433.18.151.092.281.222.54.482l2.761 2.76c.26.26.39.39.482.54a1.5 1.5 0 0 1 .18.434c.041.173.041.357.041.723v6.606c0 .84 0 1.26-.163 1.581a1.5 1.5 0 0 1-.656.656c-.32.163-.74.163-1.58.163H6.982c-.84 0-1.26 0-1.581-.163a1.5 1.5 0 0 1-.655-.656c-.164-.32-.164-.74-.164-1.58V5.316Z"
    />
    <path
      stroke="#0A1D37"
      strokeWidth={1.5}
      d="M10.417 2.917v2.6c0 .84 0 1.26.164 1.58a1.5 1.5 0 0 0 .655.656c.32.164.74.164 1.581.164h2.6"
    />
  </svg>
);
