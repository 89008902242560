import { SVGProps, useEffect } from "react";
import { textStyles } from "../../styles/typography";
import { cn } from "../../styles/utils";
import { useConfirmTransactionMutation } from "../../api/hooks";
import { useAtomValue, useSetAtom } from "jotai";
import {
  boatAtom,
  orderDataAtom,
  paymentResponseAtom,
  orderStatusAtom,
  selectedCalculationAtom,
} from "./store";

export default function PaymentPending() {
  const orderData = useAtomValue(orderDataAtom);
  const selectedCalculation = useAtomValue(selectedCalculationAtom);
  const boat = useAtomValue(boatAtom);
  const setOrderStatus = useSetAtom(orderStatusAtom);
  const setPaymentResponse = useSetAtom(paymentResponseAtom);

  const { mutate: confirmTransaction } = useConfirmTransactionMutation(
    orderData!.orderIntentId
  );

  useEffect(() => {
    async function fetchData() {
      try {
        const transaction = await confirmTransaction();

        if (transaction?.paymentStatus === "InProgress") {
          setTimeout(() => {
            fetchData();
          }, 1000);

          return;
        }

        if (transaction?.paymentStatus === "Completed") {
          window.parent.postMessage(
            {
              trackEvent: "payment",
              orderId: orderData?.orderIntentId,
              value: selectedCalculation?.TotalAmount,
              boatTitle: boat.Label,
            },
            "*"
          );
        }

        if (
          transaction?.paymentStatus === "Completed" &&
          transaction?.orderIntentStatus === "Approved"
        ) {
          setPaymentResponse(transaction);
          setOrderStatus("SUCCESS");
        }

        if (transaction?.paymentStatus === "Completed" && transaction?.orderIntentStatus === "CreationFailed") {
          setOrderStatus("INTENT_CREATION_FAILED");
        }

        if (transaction?.paymentStatus === "Failed") {
          setOrderStatus("PAYMENT_FAILED");
        }
      } catch (e) {
        console.error(e);
      }
    }

    fetchData();
  }, []);

  return (
    <div className="flex flex-col items-center p-10">
      <IconLoading className="mb-4" />
      <div className={cn(textStyles.h2, "text-blue-dark mb-3")}>
        Your payment is pending...
      </div>
      <div className={cn(textStyles.body1, "text-blue-dark mb-10")}>
        This may take some time, please wait. Once completed you will be
        automatically redirected
      </div>
    </div>
  );
}

const IconLoading = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={120}
    height={120}
    fill="none"
    {...props}
  >
    <circle cx={60} cy={60} r={60} fill="#F3F3F3" />
    <rect width={80} height={60} x={20} y={30} fill="#fff" rx={4} />
    <path
      className="origin-center animate-spin transform-box-fill-box"
      fill="#26A9E0"
      d="M61.5 58v4a1.5 1.5 0 1 1-3 0v-4a1.5 1.5 0 1 1 3 0ZM72 68.5h-4a1.5 1.5 0 1 0 0 3h4a1.5 1.5 0 1 0 0-3Zm-5.282 6.096a1.501 1.501 0 0 0-2.451 1.638c.075.182.186.348.326.487l2.828 2.829a1.503 1.503 0 0 0 2.125-2.125l-2.829-2.829ZM60 76.5a1.5 1.5 0 0 0-1.5 1.5v4a1.5 1.5 0 1 0 3 0v-4a1.5 1.5 0 0 0-1.5-1.5Zm-6.718-1.904-2.828 2.829a1.503 1.503 0 1 0 2.125 2.125l2.828-2.829a1.503 1.503 0 0 0-2.124-2.125ZM53.5 70a1.5 1.5 0 0 0-1.5-1.5h-4a1.5 1.5 0 1 0 0 3h4a1.5 1.5 0 0 0 1.5-1.5Zm-.925-9.546a1.503 1.503 0 1 0-2.125 2.125l2.832 2.825a1.503 1.503 0 0 0 2.126-2.125l-2.833-2.825Z"
    />
    <path fill="#F3F3F3" d="M20 42h80v8H20z" />
  </svg>
);
