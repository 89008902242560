import { parse } from "date-fns";
import { useSetAtom, useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import {
  CalculationPayloadType,
  CalculationReturnType,
  CustomPartDayCharterType,
} from "../../api/types";
import { getPartDaySlotDates, formatDateToApi } from "../../utils/date";
import {
  selectedDateAtom,
  boatCharterTypesLoadableAtom,
  selectedCharterTypeAtom,
  selectedSlotAtom,
  selectedCalculationAtom,
  currentCalendarMonthDateAtom,
} from "./store";
import { API_PATH } from "../../api/paths";
import { customFetch } from "../../api/common";

export default function usePredefinedCharter(setStep: (step: 1 | 2) => void) {
  const [isLoaded, setIsLoaded] = useState(false);
  
  const boatCharterTypesLoadable = useAtomValue(boatCharterTypesLoadableAtom);
  const charterTypesLoading = boatCharterTypesLoadable.state === "loading";
  const charterTypes =
  "data" in boatCharterTypesLoadable ? boatCharterTypesLoadable.data : [];
  
  const setSelectedDate = useSetAtom(selectedDateAtom);
  const setSelectedCharterType = useSetAtom(selectedCharterTypeAtom);
  const setSelectedSlot = useSetAtom(selectedSlotAtom);
  const setSelectedCalculation = useSetAtom(selectedCalculationAtom);
  const setCurrentCalendarMonth = useSetAtom(currentCalendarMonthDateAtom)

  useEffect(() => {
    async function fetchData() {
      if (isLoaded || charterTypesLoading || !charterTypes?.length) {
        return;
      }
      setIsLoaded(true);

      const searchParams = new URLSearchParams(window.location.search);
      const selectedDate = searchParams.get("date");
      const charterTypeId = searchParams.get("charter_type_id");
      const boatId = searchParams.get("boat_id");

      const parsedBoatId = boatId ? Number(boatId) : null;

      // comes in format 2024-06-19
      const parsedDate = selectedDate
        ? parse(selectedDate, "yyyy-MM-dd", new Date())
        : null;

      const selectedDateAsString = parsedDate
        ? formatDateToApi(parsedDate)
        : null;

      if (parsedDate) {
        setSelectedDate(parsedDate);
        setCurrentCalendarMonth(parsedDate)
      }

      const predefinedCharterTypeId = charterTypeId
        ? Number(charterTypeId)
        : null;

      // set the selected charter type when the predefined charter type is set
      if (predefinedCharterTypeId && selectedDateAsString) {
        const predefinedStandardCharterType = charterTypes?.find(
          (type) => type.Id === predefinedCharterTypeId
        );

        if (!predefinedStandardCharterType) {
          //   try to find it in "part" day charter types
          const partDayCharterTypes = charterTypes?.find(
            (type) => type.Id === "part"
          ) as CustomPartDayCharterType;

          const predefinedCharterType = partDayCharterTypes?.Hours.find(
            (hour) => hour.Id === predefinedCharterTypeId
          );

          if (!predefinedCharterType) {
            return;
          }

          const { slotInterval } = getPartDaySlotDates(
            predefinedCharterType,
            selectedDateAsString
          );

          setSelectedSlot({
            charterTypeId: predefinedCharterType.Id,
            interval: slotInterval,
          });

          const calculationRes = await customFetch<CalculationPayloadType>(
            API_PATH.OrderCalculationPOST(),
            "POST",
            {
              Items: [
                {
                  PricingId: predefinedCharterType.Id,
                  Passengers: 1,
                  Date: selectedDateAsString,
                  BoatId: parsedBoatId!,
                  AddonsIds: [],
                  AdditionalItemsIds: [],
                },
              ],
              Currency: "USD",
            }
          );
          const calculation =
            (await calculationRes.json()) as CalculationReturnType;

          setSelectedCalculation(calculation);
          setStep(2);
        } else {
          setSelectedCharterType(predefinedStandardCharterType ?? null);
        }
      }
    }

    fetchData();
  }, [charterTypes, setSelectedDate, setSelectedCharterType, setStep]);

  return <div>usePredefinedCharter</div>;
}
