import { useState } from "react";
import Button from "../ui/Button";
import BookingSummary from "./BookingSummary";
import CustomerDetailsForm from "./CustomerDetailsForm";
import Loading from "../ui/Loading";
import { useAtomValue } from "jotai";
import { orderDataAtom } from "./store";
import StripePaymentForm from "./StripePaymentForm";

type Props = {
  handlePrevStep: () => void;
};

export default function Step2({ handlePrevStep }: Props) {
  const [isPaymentReady, setIsPaymentReady] = useState<boolean | null>(null);
  const orderData = useAtomValue(orderDataAtom);

  return (
    <>
      <div className="grid md:grid-cols-2 md:gap-0 gap-6 h-full">
        <div className="md:p-10 p-4 pt-0 flex flex-col gap-10">
          <BackButton
            handlePrevStep={handlePrevStep}
            className="md:hidden self-start"
            small
          />

          {isPaymentReady && orderData?.clientSecret ? (
            <StripePaymentForm
              clientSecret={orderData?.clientSecret}
              setIsPaymentReady={setIsPaymentReady}
            />
          ) : (
            <>
              <CustomerDetailsForm setIsPaymentReady={setIsPaymentReady} />
              <BackButton
                handlePrevStep={handlePrevStep}
                className="mt-auto hidden md:flex"
              />
            </>
          )}
        </div>
        <div className="bg-grey-light md:p-10 px-4 py-6 pb-16 md:rounded-xl flex flex-col gap-10">
          <BookingSummary />

          {isPaymentReady === null ? (
            <Button
              type="submit"
              variant="primary"
              form="contactInformation"
              className="w-full mt-auto"
            >
              Continue to Payment
            </Button>
          ) : (
            <Button
              variant="primary"
              type="submit"
              disabled={!isPaymentReady}
              form="stripePaymentForm"
              className="w-full mt-auto"
            >
              Purchase
            </Button>
          )}
        </div>
      </div>

      {isPaymentReady === false && (
        <div className="absolute inset-0 flex items-center justify-center bg-white/50">
          <Loading />
        </div>
      )}
    </>
  );
}

const BackButton = ({
  handlePrevStep,
  className,
  small,
}: {
  handlePrevStep: () => void;
  className?: string;
  small?: boolean;
}) => (
  <Button
    variant="secondary"
    type="button"
    onClick={() => {
      window.scrollTo(0, 0);
      handlePrevStep();
    }}
    className={className}
    small={small}
  >
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none">
      <path
        fill="#2A3D8C"
        d="M13.163 15.587a.939.939 0 1 1-1.328 1.328l-6.25-6.25a.938.938 0 0 1 0-1.328l6.25-6.25a.94.94 0 1 1 1.328 1.328L7.578 10l5.585 5.587Z"
      />
    </svg>
    Back to Dates
  </Button>
);
