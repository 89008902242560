// /* H2 */
// font-family: Montserrat;
// font-size: 20px;
// font-style: normal;
// font-weight: 600;
// line-height: 28px; /* 140% */
// letter-spacing: -0.5px;

/* H1 */
// font-family: Montserrat;
// font-size: 24px;
// font-style: normal;
// font-weight: 600;
// line-height: 32px; /* 133.333% */
// letter-spacing: -0.5px;

// /* Sub1 */
// font-family: Montserrat;
// font-size: 16px;
// font-style: normal;
// font-weight: 500;
// line-height: 24px; /* 150% */
// letter-spacing: -0.5px;

// /* Label */
// font-family: Montserrat;
// font-size: 12px;
// font-style: normal;
// font-weight: 500;
// line-height: 12px; /* 100% */
// letter-spacing: -0.5px;

// TODO fix leading values
const textStylesDefault = {
  h1: "text-2xl font-semibold tracking-tight",
  h2: "font-semibold text-xl",
  h3: "text-4xl font-medium leading-12 tracking-tighter",
  h4: "text-3xl font-medium leading-12 tracking-tight",
  h5: "text-2xl font-normal leading-6 tracking-tight",
  text1: "text-base font-normal leading-5",
  text2: "text-sm font-normal leading-4",
  sub1: "font-medium tracking-tight",
  sub2: "text-base font-medium leading-5 tracking-tight",
  sub3: "text-sm font-medium leading-4 tracking-tight",
  body1: "tracking-tight",
  label: "text-xs font-medium leading-3 tracking-tight",
} as const;

export const textStyles = {
  ...textStylesDefault,
  md: {
    h1: "md:text-6xl md:font-medium md:leading-16",
    h3: "md:text-4xl md:font-medium md:leading-12 md:tracking-tighter",
    h4: "md:text-3xl md:font-medium md:leading-12 md:tracking-tight",
    h5: "md:text-2xl md:font-normal md:leading-6 md:tracking-tight",
    text1: "md:text-base md:font-normal md:leading-5",
    text2: "md:text-sm md:font-normal md:leading-4",
    sub1: "md:text-lg md:font-medium md:leading-6 md:tracking-tight",
    sub2: "md:text-base md:font-medium md:leading-5 md:tracking-tight",
    sub3: "md:text-sm md:font-medium md:leading-4 md:tracking-tight",
  },
} as const;
